<template>
  <section class="max-width">
    <h2 class="customer-header">Några nöjda kunder</h2>
    <div class="customer-container">
      <div v-for="item in data" :key="item.id" class="customer-card">
        <img :src="require('@/assets/img/customerLogo/' + item.src)" :alt="item.alt" />
        <h3>{{ item.company }}</h3>
        <p>"{{ item.quote }}"</p>
        <span>{{ item.CEO }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import customer from "@/json/customer.json";
export default {
  name: "Customer",
  data() {
    return {
      data: customer
    };
  }
};
</script>

<style>
.max-width {
  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
}
</style>
